.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-logo {
  animation: logo-spin infinite 10s linear;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html::-webkit-scrollbar {
  width: 0px;
}
html::-webkit-scrollbar-track {
  background: transparent;
}
html::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 4px;
}

.common-srollbar::-webkit-scrollbar {
  width: 0px;
}
.common-srollbar::-webkit-scrollbar-track {
  background: transparent;
}
.common-srollbar::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 4px;
}

@keyframes h-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.h-scroll {
  animation: h-scroll infinite 50s linear;
}

#chakra-toast-manager-top-right {
  top: 100px !important;
}

.clamp-line2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  display: -webkit-box;
}

.clamp-line6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
}
